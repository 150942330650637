import React, { useState, useEffect, useRef } from 'react';
import "./style.scss";
import { NMCKEditor } from "../CKEditor";
import { Button } from "../Button";
import { FormDialog } from "../Dialog";
import { AutoComplete } from "../Input";
import { Header, Footer, HistoryTable } from "./Components";
import { getDocumenterPages, Sections, getSectionPosition } from "./PageHelper";;

const DocumentTableCols = [
  { title: 'Action', dataIndex: 'action', key: 'action', width: '12%', className: 'exo2 f10 txt-left caps', cellClassName: 'f9 txt-left caps' },
  { title: 'Version', dataIndex: 'version_no', key: 'version_no', width: '8%', className: 'exo2 f10 text-center', cellClassName: 'f9 text-center' },
  { title: 'Name', dataIndex: 'username', key: 'username', width: '28%', className: 'exo2 f10 txt-left', cellClassName: 'f9 txt-left' },
  { title: 'Designation, Function', dataIndex: 'designation_function', key: 'designation_function', width: '32%', className: 'exo2 f10 txt-left', cellClassName: 'f9 txt-left' },
  { title: 'Date', dataIndex: 'created_at', key: 'created_at', width: '20%', className: 'exo2 f10 text-center', cellClassName: 'f9 text-center caps' }
]
const ChangeTableCols = [
  { title: 'Action', dataIndex: 'action', key: 'action', width: '12%', className: 'exo2 f10 txt-left caps', cellClassName: 'f9 txt-left' },
  { title: 'Version', dataIndex: 'version', key: 'version', width: '8%', className: 'exo2 f10 txt-left', cellClassName: 'f9 txt-left' },
  { title: 'Responsible', dataIndex: 'responsible', key: 'responsible', width: '25%', className: 'exo2 f10 txt-left', cellClassName: 'f9 txt-left' },
  { title: 'Description', dataIndex: 'summary', key: 'summary', width: '35%', className: 'exo2 f10 txt-left', isHTML: true, cellClassName: 'f9 txt-left' },
  { title: 'Date', dataIndex: 'created_at', key: 'created_at', width: '20%', className: 'exo2 f10 text-center', cellClassName: 'f9 text-center' }
]

const DocumentMenus = [
  { id: 'delete', label: "Remove" },
  { id: 'prev', label: "Move to Prev Page" },
  { id: 'next', label: "Move to Next Page" },
  { id: 'continue', label: "Continue to Next Page" },
  { id: 'new', label: "Continue to New Page" },
]
const DocHistoryRows = [
  { action: 'Approved', version: '', name: '[[CEOFullname]]', desc: 'Chief Executive Officer', date: '[[Ddd, dd-Mmm-yyyy]]' },
  { action: 'Approval', version: '', name: '[[ApproverFullname]]', desc: 'Head of Department, [[ApproverDept]]', date: 'Pending' },
  { action: 'Revision', version: '', name: '[[ReviewerFullname]]', desc: '', date: 'Review' },
  { action: 'Draft', version: '101', name: '[[DocCreatorFullname]]', desc: '[[FullDesignation]], InfoSec', date: '[[Ddd, dd-Mmm-yyyy]]' },
]
const ChangeHistoryDefaultRows = [
  { action: ' ', version: '', name: '', desc: '1.', date: '' }
]
const addContextMenu = (editor) => {
  const contextMenuDefinition = [
    {
      label: 'Custom Option 1',
      command: 'customOption1',
      group: 'customGroup',
    },
    {
      label: 'Custom Option 2',
      command: 'customOption2',
      group: 'customGroup',
    },
  ];
  editor.addCommand('customOption1', {
    exec: function (editorInstance) {
      console.log('Custom Option 1 clicked');
    },
  });

  editor.addCommand('customOption2', {
    exec: function (editorInstance) {
      console.log('Custom Option 2 clicked');
    },
  });
  editor.on('contentMenu', function (event) {
    console.log('ed');
    event.data = contextMenuDefinition;
  });
}
const SectionEditor = (props) => {
  const { inEditMode, section, onEditorReady, onChange, onPageOverFlow } = props;
  const ref = useRef(null)
  const handleEditorReady = (e) => {
    const editor = e.editor;
    if (editor) {
      ref.current = editor;
      addContextMenu(editor)
    }
    onEditorReady && onEditorReady(editor)
  }
  const getValue = () => {
    let value = section.value || '';
    value = value.replace(/&nbsp;/g, ' ');
    return value;
  }
  const handleOnPageAction = (e) => {
    const { value } = e.target;
    if (value && value.id) {
      let data = ref.current ? ref.current.getData() : '';
      props.onMove && props.onMove(value.id, data)
    }
  }
  const handleChange = (e) => {
    onChange && onChange(e, section)
    onPageOverFlow && onPageOverFlow(props.pageNo + 1, section)
  }
  const getMenus = () => {
    return DocumentMenus.filter((menu) => {
      let pass = true;
      switch (menu.id) {
        case DocumentMenus[0].id: pass = !Boolean(section.showSectionTitle); break;
        case DocumentMenus[1].id: pass = props.pageNo > 0 && (!section.isSubSection || (section.isSubSection && !section.showSectionTitle)); 
        break;
      }
      return pass
    })
  }
  return (
    inEditMode ?
      <React.Fragment>
        <NMCKEditor
          type="inline"
          initialData={section.value}
          onChange={handleChange}
          onInstanceReady={handleEditorReady} />
        <div className='row action'>
          {
            Boolean(props.allowAddVariable && (section.allowAddVariable !== undefined ? section.allowAddVariable : true)) &&
            <Button label='Add Variable' className='bg0133CC btn-add' onClick={() => {
              props.onAddVariable && props.onAddVariable(section.attribute, ref.current)
            }} />
          }
          {
            !section.isSubSection &&
            <AutoComplete
              primary
              name={`page-${section.attribute}`}
              placeholder="Action"
              caretClassName='c0033CC'
              options={getMenus()}
              className='page-action-select bgFFF'
              labelClassName='hide'
              onChange={handleOnPageAction} />
          }
        </div>
      </React.Fragment>
      :
      <div dangerouslySetInnerHTML={{ __html: getValue() }} className='col w-100 cke_readonly'></div>
  )
}

const PageSection = (props) => {
  const { isSchemaOnly, section, inEditMode, onEditorReady } = props;
  useEffect(() => {
    if (inEditMode && (isTableSection() || section.attribute === 'tbl_of_content' || section.onlyTitle)) {
      let editor = null
      if (section.attribute === 'tbl_of_content') {
        editor = { getData: () => section.value }
      }
      handleEditorReady(editor)
    }
  }, [inEditMode])
  const handleEditorReady = (editor) => {
    const { value, title, key, slNo, ...rest } = section
    onEditorReady && onEditorReady(rest, editor)
  }
  const getClassName = () => {
    let _className = 'col section w-100';
    _className += inEditMode ? ' edit-mode' : '';
    _className += section.isSubSection ? ' sub-section' : '';
    return _className;
  }
  const isTableSection = () => {
    return section.attribute === 'change_history' || section.attribute === 'doc_history'
  }
  const getTableColumns = () => {
    return section.attribute === 'change_history' ? ChangeTableCols : DocumentTableCols
  }
  const getTableRows = () => {
    return (
      isSchemaOnly ? (
        section.attribute === 'change_history' ? ChangeHistoryDefaultRows : DocHistoryRows
      ) : (section.value || [])
    )
  }
  const getSectionHTMLValue = () => {
    // temp fix, replace all nbsp
    let value = section.value;
    value = value.replace(/&nbsp;/g, ' ');
    // console.log(section.attribute, value);
    return value
  }
  return (
    <div className={getClassName()} id={`section-${section.attribute}`}>
      <div className='col w-100'>
        {
          Boolean(section.showSectionTitle) &&
          <div className='row w-100'>
            <strong className={`${section.isSubSection ? 'f8' : 'f7'} bold c238787 caps`}>
              {Boolean(section.slNo) && <span className='sl-no exo2'>{section.slNo}.</span>}
              <span className='exo2' dangerouslySetInnerHTML={{ __html: section.title }}></span>
            </strong>
          </div>
        }
      </div>
      <div className={`col section-content w-100 ${section.showSectionTitle && !section.slNo ? 'no-pad' : ''}`}>
        <div className='col w-100'>
          {
            !section.onlyTitle &&
            <div className={`col cke_toolbar ${section.isSubSection ? 'sub-section-content' : ''}`}>
              {
                (isTableSection() && getTableRows().length > 0) ?
                  <HistoryTable
                    isSchemaOnly={isSchemaOnly}
                    rows={getTableRows()}
                    Columns={getTableColumns()} />
                  : section.attribute === 'tbl_of_content' ?
                    <div dangerouslySetInnerHTML={{ __html: getSectionHTMLValue() || '' }}></div>
                    :
                    <SectionEditor
                      {...props}
                      onEditorReady={handleEditorReady}
                    />
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const Page = (props) => {
  const { isSchemaOnly, pageNo, document, sections, onSectionMove, onEditorReady, zoomLevel } = props;
  const getZoomLevel = () => {
    let style = {}, currentZoomLevel = zoomLevel;
    if (zoomLevel === 'fit') {
      let viewerContainer = window.document.querySelector('.document-viewer');
      if (viewerContainer) {
        currentZoomLevel = parseInt(((viewerContainer.clientWidth - 10) / 793) * 100);
      }
    }
    if (currentZoomLevel && currentZoomLevel !== 100) {
      let scale = currentZoomLevel / 100;
      let pageHeight = 29.7;
      style.transform = `scale(${scale})`;
      if (scale === 0.5 && pageNo === 0) {
        style.marginTop = '-7.4cm'
      } else {
        style.marginTop = pageHeight;
        if (pageNo === 0) {
          style.marginTop = (pageHeight / 2) + scale;
        }
        style.marginTop = `${style.marginTop * (scale - 1)}cm`
      }
      if (pageNo === (props.totalPages - 1)) {
        if (scale === 0.5) {
          style.marginBottom = '0px';
        } else {
          let rem = currentZoomLevel % 100;
          rem = rem ? rem : 100;
          style.marginBottom = `${4 * (rem / 25)}cm`;
        }
      }
    }
    return style
  }
  return (
    <div className={`col page ${isSchemaOnly ? 'bgFFDDDD' : 'bgDDFFFF'}`} id={`page-${pageNo + 1}`} style={getZoomLevel()}>
      <Header {...props} pageNo={pageNo + 1} />
      <div className='col f-rest o-hide'>
        <div className='col w-100 y-100 oy-auto page-content'>
          {
            pageNo === 0 &&
            <h1 className={`exo2 bold f2 ${isSchemaOnly ? 'c882222' : 'c238787'}  text-center doc-title`}>{!isSchemaOnly ? document.name : '[[DocumentTitle]]'}</h1>
          }
          {
            sections.map((section, index) => {
              return (
                <PageSection
                  {...props}
                  key={section.attribute}
                  section={section}
                  onMove={(action, value) => {
                    onSectionMove && onSectionMove(action, value, index)
                  }}
                  onEditorReady={(sectionConfig, editor) => {
                    onEditorReady && onEditorReady(pageNo, index, sectionConfig, editor)
                  }}
                />
              )
            })
          }
        </div>
      </div>
      <Footer  {...props} pageNo={pageNo + 1} />
    </div>
  )
}

export const DocumentEditor = React.forwardRef((props, ref) => {
  const { document, user, isSchemaOnly, zoomLevel, inEditMode } = props;
  const [pages, setPages] = useState(null)
  const [pageOverflow, setPageOverflow] = React.useState(null)
  const [showActionError, setShowActionError] = React.useState(false)
  const sectionEditors = useRef([])
  const _documentRef = React.useRef(null)
  useEffect(() => {
    let timer
    if (document && !pages) {
      const options = { document, user, isSchemaOnly }
      let pages = getDocumenterPages(options);
      setPages(pages)
      timer = setTimeout(() => {
        if (timer) {
          clearTimeout(initSectionLinks)
        }
        initSectionLinks();
        // organizePagesSections(pages);
      }, 1000)
    }
  }, [document]);
  useEffect(() => {
    initSectionLinks()
  }, [inEditMode])
  const initSectionLinks = () => {
    let allLinks = window.document.querySelectorAll('.section-content-html a');
    Array.prototype.map.call(allLinks, (link) => {
      link.addEventListener('click', handleContentLink)
    })
    if (inEditMode) {
      Array.prototype.map.call(allLinks, (link) => {
        link.removeEventListener('click', handleContentLink)
      })
    }
  }
  const handleContentLink = (e) => {
    window.open(e.target.href, '_blank');
    e.preventDefault();
  }
  const getDocumentData = () => {
    let body = {}, pageConfig = [];
    sectionEditors.current.forEach((sections, index) => {
      pageConfig[index] = []
      sections.forEach((section, j) => {
        const { editor, ...rest } = section;
        let attribute = section.attribute;
        let key = `${index}_${j}`;
        if (rest.isSubSection) {
          attribute = section.mainAttribute;
          key += `_${section.attribute}`
        }
        pageConfig[index][j] = { ...rest, key };
        if (!body[attribute]) {
          body[attribute] = {};
        }
        if (!rest.onlyTitle) {
          body[attribute][key] = (editor && editor.getData) ? editor.getData() : ''
        }
      })
    });
    body.page_config = JSON.stringify(pageConfig);
    // console.log("body", body);
    return body
  }
  const handleEditorReady = (page, index, sectionConfig, editor) => {
    if (!sectionEditors.current[page]) {
      sectionEditors.current[page] = [];
    }
    sectionEditors.current[page][index] = { ...sectionConfig, editor: editor };
  }
  const handleEditorSectionMoved = (action, pageNo, index) => {
    switch (action) {
      case 'remove':
        sectionEditors.current[pageNo].splice(index, 1);
        if (sectionEditors.current[pageNo].length === 0) {
          sectionEditors.current.splice(pageNo, 1);
        }
        break;
      case 'add-begin':
        if (!sectionEditors.current[pageNo]) {
          sectionEditors.current[pageNo] = [];
        }
        sectionEditors.current[pageNo].unshift({})
        break;
      case 'add-end':
        if (!sectionEditors.current[pageNo]) {
          sectionEditors.current[pageNo] = [];
        }
        sectionEditors.current[pageNo].push({})
        break;
    }
  }
  const handleShowActionError = (value) => {
    setShowActionError(value)
  }
  const handlePageOverflow = (pageNo, section) => {
    setPageOverflow({ pageNo, section })
  }
  const handleCloseOverflowWarning = () => {
    setPageOverflow(null)
  }
  const handleSectionMove = (action, value, sectionIndex, pageNo) => {
    let allowSectionMove = true, currentSecPos;
    const currentPages = pages.map((sections) => {
      return sections.map(_ => ({ ..._ }))
    });
    let section = currentPages[pageNo][sectionIndex], newPageNo;
    currentSecPos = getSectionPosition(section.attribute);
    switch (action) {
      case DocumentMenus[2].id: // next
      case DocumentMenus[3].id: // continue
      case DocumentMenus[4].id: // new
        for (let i = (sectionIndex + 1); i < currentPages[pageNo].length; i++) {
          const secPos = getSectionPosition(currentPages[pageNo][i].attribute);
          if (secPos > currentSecPos) {
            allowSectionMove = false;
            break;
          }
        }
        break;
      case DocumentMenus[1].id: // prev
        for (let i = (sectionIndex - 1); i >= 0; i--) {
          const secPos = getSectionPosition(currentPages[pageNo][i].attribute);
          if (secPos < currentSecPos) {
            allowSectionMove = false;
            break;
          }
        }
        break;
    }

    if (!allowSectionMove) {
      return handleShowActionError(true);
    }
    switch (action) {
      case DocumentMenus[0].id:
        currentPages[pageNo].splice(sectionIndex, 1);
        newPageNo = pageNo - 1;
        currentPages[newPageNo].push({ ...section, value: value });
        if (currentPages[pageNo].length === 0) {
          currentPages.splice(pageNo, 1);
        }
        handleEditorSectionMoved('remove', pageNo, sectionIndex)
        handleEditorSectionMoved('add-end', newPageNo)
        break;
      case DocumentMenus[1].id:
        currentPages[pageNo].splice(sectionIndex, 1);
        newPageNo = pageNo + 1;
        if (!currentPages[newPageNo]) {
          currentPages.push([{ ...section, value: value }])
        } else {
          currentPages[newPageNo].unshift({ ...section, value: value })
        }
        handleEditorSectionMoved('remove', pageNo, sectionIndex)
        handleEditorSectionMoved('add-begin', newPageNo)
        break;
      case DocumentMenus[2].id:
        let newSection = { ...section, showSectionTitle: false, value: '' }
        currentPages.forEach((sections, index) => {
          let found = sections.find((_) => _.attribute === section.attribute);
          if (found) {
            newPageNo = index;
          }
        })
        newPageNo++;
        if (!currentPages[newPageNo]) {
          currentPages.push([newSection])
        } else {
          currentPages[newPageNo].unshift(newSection)
        }
        handleEditorSectionMoved('add-begin', newPageNo)
        break;
    };
    setPages(currentPages)
  }
  const handleSectionOverflowHighlight = (iPageNo, iSection, iShouldAlert) => {
    if (_documentRef.current) {
      const pageDom = _documentRef.current.querySelector(`#page-${iPageNo}`);
      if (pageDom) {
        const pageContent = pageDom.querySelector('.page-content')
        const isOverflowing = pageContent.scrollHeight > pageContent.clientHeight;
        const pageSections = pageContent.querySelectorAll('.section');
        let traverseLen = 0, containerHeight = pageContent.clientHeight;
        if (iPageNo === 1) { //remove title height
          let doctitle = pageContent.querySelector('.doc-title-section');
          if (doctitle) {
            containerHeight -= doctitle.clientHeight
          }
        }
        for (let i = 0; i < pageSections.length; i++) {
          const section = pageSections[i];
          let sectionDom = section.querySelector(`.cke_toolbar .${props.inEditMode ? 'cke_editable' : 'cke_readonly'}`)
          if (!sectionDom) {
            continue;
          }
          const cssClassAdded = sectionDom.classList.contains('out-of-page');
          console.log("isOverflowing", iPageNo, isOverflowing);

          if (isOverflowing) {
            let sectionHeight = section.scrollHeight;
            if (props.inEditMode) {
              //remove action height
              let action = section.querySelector('.action');
              if (action) {
                sectionHeight -= action.clientHeight
              }
            }
            traverseLen += sectionHeight;
            if (traverseLen > containerHeight) {
              if (!cssClassAdded) {
                sectionDom.classList.add('out-of-page');
              }
            } else {
              if (cssClassAdded) {
                sectionDom.classList.remove('out-of-page')
              }
            }
          } else if (cssClassAdded) {
            sectionDom.classList.remove('out-of-page')
          }
        }
        if (iShouldAlert && isOverflowing) {
          handlePageOverflow(iPageNo, iSection)
        }
      }
    }
  }
  React.useImperativeHandle(ref, () => ({ getDocumentData: getDocumentData }))
  return (
    <div className='col w-100 h-100 oy-auto document-viewer' ref={_documentRef}>
      {
        Boolean(pages) &&
        <React.Fragment>
          {
            pages.map((sections, index) => {
              return (
                <Page
                  {...props}
                  zoomLevel={zoomLevel}
                  key={index}
                  sections={sections}
                  pageNo={index}
                  totalPages={pages.length}
                  onEditorReady={handleEditorReady}
                  onPageOverFlow={handleSectionOverflowHighlight}
                  onSectionMove={(action, value, sectionIndex) => handleSectionMove(action, value, sectionIndex, index)}
                />
              )
            })
          }
        </React.Fragment>
      }
      {
        Boolean(pageOverflow) &&
        <FormDialog
          title='Use New Page!'
          className='discard-modal'
          titleClass='f6 cFE3333 med'
          onClose={handleCloseOverflowWarning}
          rightBtn={{ label: "Okay", onClick: handleCloseOverflowWarning }}>
          <p className='f14 line-22'>
            Page {pageOverflow.pageNo} is filled. Move the remaining content to the next page.<br />
            Use the "Continue to New Page" option.
          </p>
        </FormDialog>
      }
      {
        Boolean(showActionError) &&
        <FormDialog
          title='Invalid!'
          className='discard-modal'
          titleClass='f6 cFE3333 med'
          onClose={handleShowActionError.bind(null, false)}
          rightBtn={{ label: "Okay", onClick: handleShowActionError.bind(null, false) }}>
          <p className='f14 line-22'>
            Section movement is not permitted as it would cause a mismatch in the section order.<br />
          </p>
        </FormDialog>
      }
    </div>
  )
});
export const PageSections = Sections;